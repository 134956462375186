<template>
  <div class="wrapper">
    <video x5-video-player-type="h5" :controls="true" :src="$store.state.play"></video>
    <img class="back_img" @click="call_back" src="../../assets/backs.png" alt="">
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
  },
  methods:{
    call_back() { this.$router.go(-1); },
  },
};
</script>

<style scoped>
video {
  width: 100%;
  height: 300px;
  margin-top: 140px;
}
.wrapper {
  width: 100%;
  height: 100vh;
  background: #000;
}
.back_img {
  width: 40px;
  height: 40px;
  position: fixed;
  right: 10px;
  bottom: 150px;
  z-index: 99999999;
}
</style>
